import "../scss/ScrollHandler.scss";

class ScrollHandler {

  constructor() {
    this.button = null;
    this.sentinel = null;
    this.observer = null;
    this.scrollUpIconHTML = '';
    this.scrollDownIconHTML = '';
  }

  // Initialize the ScrollHandler with the button, sentinel elements, and HTML for both icons
  initialize() {

    console.log('ScrollHandler initialization');
    this.button = document.querySelector('[data-scroll-button]');
    this.sentinel = document.querySelector(this.button.getAttribute('data-scroll-button'));

    // If either the button or sentinel is not found, do not proceed with instantiation
    if (!this.button || !this.sentinel) return;

    this.scrollUpIconHTML = this.button.getAttribute('data-scroll-icon-up');
    this.scrollDownIconHTML = this.button.getAttribute('data-scroll-icon-down');

    this._initObserver(); // Set up IntersectionObserver
    this.button.addEventListener("click", () => this._handleButtonClick());

    this._updateButtonIcon('up'); // Default to scroll up
  }

  // Helper function to get DOM element by selector or return the element itself
  _getElement(element) {
    return typeof element === "string" ? document.querySelector(element) : element;
  }

  // Initialize the intersection observer
  _initObserver() {
    if (this.observer) return; // Only initialize once

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const direction = entry.isIntersecting ? 'down' : 'up';
        this._updateButtonIcon(direction);
      });
    }, { threshold: 0.1 });

    this.observer.observe(this.sentinel);
  }

  // Update the button's icon, background color, and click action based on direction
  _updateButtonIcon(direction) {
    const iconHTML = direction === 'down' ? this.scrollDownIconHTML : this.scrollUpIconHTML;
    const action = direction === 'down' ? this._scrollDown : this._scrollToTop;

    // Parse the iconHTML string into a real DOM element to extract aria-label
    const tempWrapper = document.createElement('div');
    tempWrapper.innerHTML = iconHTML;
    const iconElement = tempWrapper.querySelector('i');
    const ariaLabel = iconElement?.getAttribute('aria-label') || '';

    // Set the button's title and aria-label using the icon's aria-label
    this.button.title = ariaLabel;
    this.button.setAttribute('aria-label', ariaLabel);
    this.button.dataset.scrollButton = direction;

    // Set the button's inner HTML to the icon
    if (this.button.innerHTML !== iconHTML) {
      this.button.innerHTML = iconHTML;
    }

    // Remove previous click listeners and rebind the new one
    this.button.removeEventListener("click", this._scrollToTop);
    this.button.removeEventListener("click", this._scrollDown);
    this.button.addEventListener("click", action.bind(this));
  }

  // Handle the button click for scrolling based on current scroll direction
  _handleButtonClick() {
    const action = this.button.dataset.scrollButton === 'down' ? this._scrollDown : this._scrollToTop;
    action.call(this);
  }

  // Scroll to top of the page
  _scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  // Scroll down to the next section (next viewport)
  _scrollDown() {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
  }
}

export default ScrollHandler;
