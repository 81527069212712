class DynamicDataLoader {

    constructor(resultProcessor) {
        this.actionAttribute = "data-loader-action"; // Attribute for actions
        this.optionsAttribute = "data-loader-options"; // Attribute for options
        this.resultProcessor = resultProcessor || DynamicDataLoader.defaultResultProcessor;
        this.boundEventHandler = this.eventHandler.bind(this);
    }

    /**
     * Initializes the event listener for the specified container.
     * @param {HTMLElement} container - The container to listen for events (default: document).
     */
    init(container = document) {
        this.container = container;
        this.container.addEventListener("click", this.boundEventHandler);
        this.container.addEventListener("change", this.boundEventHandler);
    }

    /**
     * Removes the event listener from the container.
     */
    destroy() {
        if (this.container) {
            this.container.removeEventListener("click", this.boundEventHandler);
            this.container.removeEventListener("change", this.boundEventHandler);
        }
    }

    /**
     * General event handler for both click and change events.
     * @param {Event} event - The event object.
     */
    eventHandler(event) {
        const trigger = event.target.closest(`[${this.actionAttribute}]`);
        if (!trigger) return;

        // Skip event if the trigger is a SELECT element (for click)
        if (event.type === "click" && trigger.tagName === "SELECT") {
            return;
        }

        event.preventDefault();

        const action = trigger.getAttribute(this.actionAttribute);
        let options;

        try {
            options = JSON.parse(trigger.getAttribute(this.optionsAttribute) || "{}");
        } catch (error) {
            console.error("Invalid JSON in data-loader-options attribute:", error);
            return;
        }

        options.trigger = trigger;
        options.event = event;

        if (action && options.target) {
            this.resultProcessor(action, options, this);
        } else {
            console.warn("Missing required properties in data-loader-options:", options);
        }
    }

    /**
     * Fetches data from a given URL.
     * @param {string} url - The URL to fetch data from.
     * @returns {Promise<any>} The fetched data.
     */
    async loadJson(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.statusText}`);
            }
            return await response.json();
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    }

    async submitForm(form) {
        const formData = new FormData(form);
        try {
            const response = await fetch(form.action, {
                method: form.method || "POST",
                body: formData,
            });
            if (!response.ok) {
                throw new Error(`Form submission failed: ${response.statusText}`);
            }
            return await response.json();
        } catch (error) {
            console.error("Error submitting form:", error);
            throw error;
        }
    }

    /**
     * The default result processor for handling actions.
     * @param {string} action - The action to perform.
     * @param {Object} options - The options object.
     * @param {DynamicDataLoader} loaderInstance - The instance of the data loader.
     */
    static async defaultResultProcessor(action, options, loaderInstance) {

        const triggerElement = options.trigger;
        const targetElement = document.querySelector(options.target);

        try {
            let json, form;

            // Fetch data only once for actions that need it
            if (["insertOptions", "insertList", "insertHTML"].includes(action)) {
                if (options.url) {
                    let url = options.url;
                    if (targetElement.tagName === "SELECT") {
                        url += '?id=' + options.event.target.value;
                    }
                    json = await loaderInstance.loadJson(url);
                } else if (options.json) {
                    json = { success: true, data: JSON.parse(options.json) };
                }

                if (!json.success) {
                    console.warn("Failed to load data for action:", action);
                    return;
                }
            }

            // Handle form submission actions
            if (["insertFormHtml", "insertFormList"].includes(action)) {
                form = triggerElement.closest("form");
                if (!form) {
                    console.warn("No form found for the submit button.");
                    return;
                }
                json = await loaderInstance.submitForm(form);
                if (json.success === false) {
                    console.warn("Invalid or missing response data.");
                    return;
                }
            }

            // Perform the action
            switch (action) {
                case "insertHTML":
                case "insertFormHtml":
                    if (typeof json.data === "string") {
                        targetElement.innerHTML = json.data; // Use JSON data as HTML content
                    } else {
                        console.warn("Invalid or missing HTML content in response:", json);
                    }
                    break;

                case "insertList":
                case "insertFormList":
                    if (Array.isArray(json.data)) {
                        const separator = options.separator || "; ";
                        const existingContent =
                            (["INPUT", "TEXTAREA"].includes(targetElement.tagName) ? targetElement.value : targetElement.textContent)
                            .split(separator)
                            .map((item) => item.trim())
                            .filter(Boolean);
                        const combinedData = Array.from(new Set([...existingContent, ...json.data]));
                        if (["INPUT", "TEXTAREA"].includes(targetElement.tagName)) {
                            targetElement.value = combinedData.join(separator);
                        } else {
                            targetElement.textContent = combinedData.join(separator);
                        }
                    } else {
                        console.warn("Invalid data for insertList action:", json);
                    }
                    break;

                case "insertOptions":
                    if (targetElement.tagName === "SELECT" && Array.isArray(json.data)) {
                        targetElement.innerHTML = ""; // Clear existing options
                        json.data.forEach((item) => {
                            const option = document.createElement("option");
                            option.value = item.value || item.id || '';
                            option.textContent = item.label;
                            targetElement.appendChild(option);
                        });
                    } else {
                        console.warn("Invalid data for insertOptions action:", json);
                    }
                    break;

                case "hide":
                    targetElement.style.display = "none";
                    break;

                case "clear":
                    if (["INPUT", "TEXTAREA"].includes(targetElement.tagName)) {
                        targetElement.value = "";
                    } else {
                        targetElement.innerHTML = "";
                    }
                    break;

                default:
                    console.warn("Unsupported action:", action);
            }
        } catch (error) {
            console.error("Error processing result:", error);
        }
    }
}

export default DynamicDataLoader;



// class DynamicDataLoader {

//     constructor(resultProcessor) {
//         this.actionAttribute = "data-loader-action"; // Attribute for actions
//         this.optionsAttribute = "data-loader-options"; // Attribute for options
//         this.resultProcessor = resultProcessor || DynamicDataLoader.defaultResultProcessor;
//         this.boundClickHandler = this.clickHandler.bind(this);
//         this.boundChangeHandler = this.changeHandler.bind(this);
//     }

//     /**
//      * Initializes the event listener for the specified container.
//      * @param {HTMLElement} container - The container to listen for click events (default: document).
//      */
//     init(container = document) {
//         this.container = container;
//         this.container.addEventListener("click", this.boundClickHandler);
//         this.container.addEventListener("change", this.boundChangeHandler);
//     }

//     /**
//      * Removes the event listener from the container.
//      */
//     destroy() {
//         if (this.container) {
//             this.container.removeEventListener("click", this.boundClickHandler);
//             this.container.removeEventListener("change", this.boundChangeHandler);
//         }
//     }

//     /**
//      * Handles click events and triggers the appropriate action.
//      * @param {MouseEvent} event - The click event object.
//      */
//     clickHandler(event) {
//         const trigger = event.target.closest(`[${this.actionAttribute}]`);
        
//         // Check if the trigger is a SELECT element and exit early if it is
//         if (trigger && trigger.tagName === "SELECT") {
//             return; // Do nothing if the trigger is a SELECT element
//         }
    
//         if (trigger) {
//             event.preventDefault();
    
//             const action = trigger.getAttribute(this.actionAttribute);
//             let options;
    
//             try {
//                 options = JSON.parse(trigger.getAttribute(this.optionsAttribute) || "{}");
//             } catch (error) {
//                 console.error("Invalid JSON in data-loader-options attribute:", error);
//                 return;
//             }
//             options.trigger = trigger;
//             options.event = event;
    
//             if (action && options.target) {
//                 this.resultProcessor(action, options, this);
//             } else {
//                 console.warn("Missing required properties in data-loader-options:", options);
//             }
//         }
//     }
    
//     /**
//      * Handles change events and triggers the appropriate action.
//      * @param {Event} event - The change event object.
//      */
//     changeHandler(event) {
//         const trigger = event.target.closest(`[${this.actionAttribute}]`);
        
//         // Check if the trigger is a SELECT element and exit early if it is not
//         if (trigger && trigger.tagName !== "SELECT") {
//             return; // Do nothing if the trigger is a SELECT element
//         }

//         if (trigger) {
//             const action = trigger.getAttribute(this.actionAttribute);
//             let options;
//             try {
//                 options = JSON.parse(trigger.getAttribute(this.optionsAttribute) || "{}");
//             } catch (error) {
//                 console.error("Invalid JSON in data-loader-options attribute:", error);
//                 return;
//             }
//             options.trigger = trigger;
//             options.event = event;

//             if (action && options.target) {
//                 this.resultProcessor(action, options, this);
//             } else {
//                 console.warn("Missing required properties in data-loader-options:", options);
//             }
//         }
//     }    

//     /**
//      * Fetches data from a given URL.
//      * @param {string} url - The URL to fetch data from.
//      * @returns {Promise<any>} The fetched data.
//      */
//     async loadJson(url) {
//         try {
//             const response = await fetch(url);
//             if (!response.ok) {
//                 throw new Error(`Failed to fetch: ${response.statusText}`);
//             }
//             return await response.json();
//         } catch (error) {
//             console.error("Error loading data:", error);
//             throw error;
//         }
//     }

//     async submitForm(form) {
//         const formData = new FormData(form);
//         try {
//             const response = await fetch(form.action, {
//                 method: form.method || "POST",
//                 body: formData,
//             });
//             if (!response.ok) {
//                 throw new Error(`Form submission failed: ${response.statusText}`);
//             }
//             return await response.json();
//         } catch (error) {
//             console.error("Error submitting form:", error);
//             throw error;
//         }
//     }

//     /**
//      * The default result processor for handling actions.
//      * @param {string} action - The action to perform.
//      * @param {Object} options - The options object.
//      * @param {DynamicDataLoader} loaderInstance - The instance of the data loader.
//      */
//     static async defaultResultProcessor(action, options, loaderInstance) {

//         const triggerElement = options.trigger;
//         const targetElement  = document.querySelector(options.target);

//         try {
//             // Fetch data only once for actions that need it
//             let json, form;

//             if (["insertOptions", "insertList", "insertHTML"].includes(action)) {
//                 if (options.url) {
//                     let url = options.url;
//                     if (targetElement.tagName === "SELECT") {
//                         url += '?id=' + options.event.target.value;
//                     }
//                     json = await loaderInstance.loadJson(url);

//                 } else if (options.json) {
//                     json = {success: true, data: JSON.parse(options.json)};
//                 }
//                 if (!json.success) {
//                     console.warn("Failed to load data for action:", action);
//                     return;
//                 }
//             }


//             if (["insertFormHtml", "insertFormList"].includes(action)) {
//                 form = triggerElement.closest("form");
//                 if (!form) {
//                     console.warn("No form found for the submit button.");
//                     return;
//                 }
//                 json = await loaderInstance.submitForm(form);
//                 if (json.success === false) {
//                     console.warn("Invalid or missing response data.");
//                     return;
//                 }
//             }

//             switch (action) {
//                 case "insertHTML":
//                 case "insertFormHtml":
//                     if (typeof json.data === "string") {
//                         targetElement.innerHTML = json.data; // Use JSON data as HTML content
//                     } else {
//                         console.warn("Invalid or missing HTML content in response:", json);
//                     }
//                     break;

//                 case "insertList":
//                 case "insertFormList":
//                     if (Array.isArray(json.data)) {
//                         const separator = options.separator || "; ";
//                         const existingContent = 
//                             (["INPUT", "TEXTAREA"].includes(targetElement.tagName) ? targetElement.value : targetElement.textContent)
//                             .split(separator)
//                             .map((item) => item.trim())
//                             .filter(Boolean);
//                         const combinedData = Array.from(new Set([...existingContent, ...json.data]));
//                         if (["INPUT", "TEXTAREA"].includes(targetElement.tagName)) {
//                             targetElement.value = combinedData.join(separator);
//                         } else {
//                             targetElement.textContent = combinedData.join(separator);
//                         }
//                     } else {
//                         console.warn("Invalid data for insertList action:", json);
//                     }
//                     break;

//                 case "insertOptions":
//                     if (targetElement.tagName === "SELECT" && Array.isArray(json.data)) {
//                         targetElement.innerHTML = ""; // Clear existing options
//                         json.data.forEach((item) => {
//                             const option = document.createElement("option");
//                             option.value = item.value || item.id || '';
//                             option.textContent = item.label;
//                             targetElement.appendChild(option);
//                         });
//                     } else {
//                         console.warn("Invalid data for insertOptions action:", json);
//                     }
//                     break;

//                 case "hide":
//                     targetElement.style.display = "none";
//                     break;
    
//                 case "clear":
//                     if (["INPUT", "TEXTAREA"].includes(targetElement.tagName)) {
//                         targetElement.value = "";
//                     } else {
//                         targetElement.innerHTML = "";
//                     }
//                     break;

//                 default:
//                     console.warn("Unsupported action:", action);
//             }
//         } catch (error) {
//             console.error("Error processing result:", error);
//         }
//     }
// }

// export default DynamicDataLoader;
