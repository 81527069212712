/* CatalogApp.js */
class CatalogApp {
    constructor(catalog) {
        this.catalog = catalog;
        this.container = null;
        this.baseUrl = null;

        this.init();  // Initialize first
        this.initEventListeners();
        this.setupAppMethods();
    }

    /**
     * Initialize event listeners
     */
    initEventListeners() {
        document.addEventListener('catalog-cart-update', (event) => {
            const target = `appvimar://native/updateCartNum:${event.detail.quantity}`;
            window.location.href = target;  // Trigger app action
        });
    }

    /**
     * Setup application methods on the vimar app
     */
    setupAppMethods() {
        // Ensure window.vimar exists
        window.vimar = window.vimar || {};  
        window.vimar.app = window.vimar.app || {}; 

        // Add runSearch method to the app
        window.vimar.app.runSearch = (value) => {
            const term = encodeURIComponent(String(value));
            window.location.href = `${this.baseUrl}/search?term=${term}`;
        };

        // Add dropCart method to the app
        window.vimar.app.dropCart = function dropCart(value) {
            // console.log('drop cart');
            if (this.catalog !== undefined) {
                this.catalog.showCart();
            }
        }
    }

    /**
     * Initialize the catalog app
     */
    init() {
        console.log('CatalogApp initialization');
        this.container = document.querySelector('[data-catalog-namespace="catalog"]');
        
        // Ensure baseUrl exists, fallback if not
        this.baseUrl = this.container ? this.container.getAttribute('data-catalog-baseurl') : null;

        if (!this.baseUrl) {
            console.error('Base URL is missing on the catalog container element.');
        }
    }    
}

export default CatalogApp;



// /* CatalogApp.js */
// class CatalogApp {
//     constructor(catalog) {
//         this.catalog = catalog;
//         this.container = null;
//         this.baseUrl = null;

//         this.initEventListeners();
//         this.setupAppMethods();
//         this.init();
//     }

//     /**
//      * Initialize event listeners
//      */
//     initEventListeners() {
//         document.addEventListener('catalog-cart-update', (event) => {
//             const target = `appvimar://native/updateCartNum:${event.detail.quantity}`;
//             window.location.href = target;
//         });
//     }

//     /**
//      * Setup application methods on the vimar app
//      */
//     setupAppMethods() {
//         window.vimar = window.vimar || {};  // Ensure window.vimar exists
//         window.vimar.app = window.vimar.app || {};  // Then ensure window.vimar.app exists

//         window.vimar.app.runSearch = (value) => {
//             // window.location.href = `${this.baseurl}/search?term=${value.rawurlencode()}`;
//             const term = encodeURIComponent(String(value));
//             window.location.href = `${this.baseurl}/search?term=${term}`;
//         };

//         window.vimar.app.dropCart = () => {
//             if (this.catalog) {
//                 this.catalog.showCart();
//             }
//         };
//     }

//     init() {
//         console.log('CatalogApp initialization');
//         this.container = document.querySelector('[data-catalog-namespace="catalog"]');
//         this.baseUrl = this.container.getAttribute('data-catalog-baseurl');
//     }    
// }

// export default CatalogApp;